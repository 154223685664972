import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../pages/dashboard/Tabs";
import CustomerList from "../../pages/dashboard/CustomerList";
import { useAuth } from "../../hooks/useAuth";
import Style from "./DashboardTabs.module.css";
import { getList } from "../../services/userServices";
import {
  setActiveDashboardSubTab,
  setCompletedUserList,
  setTodaysListCount,
  setPendingUserList,
  setSelectedContacts,
  setTotalUserList,
  setCategoriesData,
  setYesterdaysList,
  setYestedaysCount,
  setTodaysCount,
  setTomorrowsCount,
  setTodaysList,
  setTomorrowsList,
  setRestaurantsList,
  setActiveDashboardTab,
} from "../../redux/slices/masterDataSlice";
import {
  ENDPOINT,
  ERROR_ACTIONS,
  TOAST_CONFIG,
  USER_TYPE,
} from "../../config/constants";
import { Search } from "@mui/icons-material";
import Reservations from "../../pages/dashboard/Reservations";
import { GET, POST_MULTIPART } from "../../services/axiosRequestHandler";
import { toast } from "react-toastify";
import NoDataFound from "../../assets/images/no-data.svg";
import { PAGE_SIZE } from "../../config/config";

const ReservationTab = () => {
  const dispatch = useDispatch();
  const {
    reservationCount,
    yesterdaysList,
    todaysList,
    tomorrowsList,
    categories,
  } = useSelector((state) => state.masterData);
  const { selectedContacts, restaurantsList } = useSelector(
    (state) => state.masterData
  );
  const { logout, role } = useAuth();
  const [searchKey, setSearchKey] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryState, setCategoryState] = useState("");
  const [activeSubTab, setActiveSubTab] = useState("today");
  const [restId, setRestId] = useState("");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery("(max-width:650px)");
  const [searchLoading, setSearchLoading] = useState(false);
  const [refreshOriginalListing, setRefreshOriginalListing] = useState(0);
  const tabsData = [
    {
      name: "Yesterday",
      id: "yesterday",
      count: reservationCount?.yesterday,
    },
    {
      name: "Today",
      id: "today",
      count: reservationCount?.today,
    },
    {
      name: "Tomorrow",
      id: "tomorrow",
      count: reservationCount?.tomorrow,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getTodaysList();
      await getYesterdaysList();
      await getTomorrowsList();
      setLoading(false);
    };
    getData();
    setSearchData(getDataToRender());

    return () => {
      dispatch(setYesterdaysList([]));
      dispatch(setTodaysList([]));
      dispatch(setTomorrowsList([]));
    };
  }, [restId, currentPage]);

  useEffect(() => {
    getAllRestaurants();
  }, []);

  useEffect(() => {
    setSearchData(getDataToRender());
    setTotalPageCount(
      getRecordCount() > PAGE_SIZE ? Math.ceil(getRecordCount() / PAGE_SIZE) : 0
    );
    return () => {
      dispatch(setYesterdaysList([]));
      dispatch(setTodaysList([]));
      dispatch(setTomorrowsList([]));
    };
  }, [loading, activeSubTab, restId, refreshOriginalListing]);

  useEffect(() => {
    const searchData = setTimeout(() => {
      searchKey ? searchList() : getData();
    }, 1000);

    return () => {
      clearTimeout(searchData);
      setSearchData([]);
      setSearchLoading(true);
    };
  }, [searchKey, restId, activeSubTab]);

  const getData = async () => {
    if (activeSubTab === "yesterday") {
      await getYesterdaysList();
    } else if (activeSubTab === "today") {
      await getTodaysList();
    } else if (activeSubTab === "tomorrow") {
      await getTomorrowsList();
    }
    setRefreshOriginalListing((prev) => prev + 1);
    setSearchLoading(false);
  };

  const searchList = async () => {
    setSearchData([]);
    try {
      const payload = new FormData();
      // payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      payload.append("restId", restId);
      payload.append("search", searchKey);
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? activeSubTab === "yesterday"
            ? ENDPOINT.SEARCH_YESTERDAYS_LIST_ADMIN
            : activeSubTab === "today"
            ? ENDPOINT.SEARCH_TODAYS_LIST_ADMIN
            : activeSubTab === "tomorrow"
            ? ENDPOINT.SEARCH_TOMORROWS_LIST_ADMIN
            : ""
          : activeSubTab === "yesterday"
          ? ENDPOINT.SEARCH_YESTERDAYS_LIST
          : activeSubTab === "today"
          ? ENDPOINT.SEARCH_TODAYS_LIST
          : activeSubTab === "tomorrow"
          ? ENDPOINT.SEARCH_TOMORROWS_LIST
          : "",
        payload
      );
      if (resp?.response?.data?.status === 200) {
        setSearchData(resp?.response?.data?.data);
        if (activeSubTab === "yesterday") {
          dispatch(setYesterdaysList(resp?.response?.data));
          dispatch(setYestedaysCount(resp?.response?.data?.data?.length));
        } else if (activeSubTab === "today") {
          dispatch(setTodaysList(resp?.response?.data));
          dispatch(setTodaysCount(resp?.response?.data?.data?.length));
        } else if (activeSubTab === "tomorrow") {
          dispatch(setTomorrowsList(resp?.response?.data));
          dispatch(setTomorrowsCount(resp?.response?.data?.data?.length));
        }
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setSearchLoading(false);
      setRefreshOriginalListing((prev) => prev + 1);
    }
  };
  const getAllRestaurants = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_ALL_RESTAURANTS_FOR_ADMIN
          : ENDPOINT.GET_ALL_RESTAURANTS
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const getYesterdaysList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    payload.append("restId", restId);
    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_YESTERDAYS_LIST_ADMIN
        : ENDPOINT.GET_YESTERDAYS_LIST,
      payload
    );
    if (!resp?.error) {
      dispatch(setYesterdaysList(resp?.data));
      dispatch(setYestedaysCount(resp?.data?.totalRecordsCount || 0));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };
  const getTodaysList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    payload.append("restId", restId);

    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_TODAYS_LIST_ADMIN
        : ENDPOINT.GET_TODAYS_LIST,
      payload
    );
    if (!resp?.error) {
      dispatch(setTodaysList(resp?.data));
      dispatch(setTodaysCount(resp?.data?.totalRecordsCount || 0));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getTomorrowsList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    payload.append("restId", restId);

    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_TOMORROWS_LIST_ADMIN
        : ENDPOINT.GET_TOMORROWS_LIST,
      payload
    );
    if (!resp?.error) {
      dispatch(setTomorrowsList(resp?.data));
      dispatch(setTomorrowsCount(resp?.data?.totalRecordsCount || 0));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getDataToRender = () => {
    if (activeSubTab === "yesterday") {
      return yesterdaysList?.data?.data || [];
    } else if (activeSubTab === "today") {
      return todaysList?.data?.data || [];
    } else if (activeSubTab === "tomorrow") {
      return tomorrowsList?.data?.data || [];
    } else {
      return [];
    }
  };

  const getRecordCount = () => {
    if (activeSubTab === "yesterday") {
      return yesterdaysList?.data?.totalRecordsCount || 0;
    } else if (activeSubTab === "today") {
      return todaysList?.data?.totalRecordsCount || 0;
    } else if (activeSubTab === "tomorrow") {
      return tomorrowsList?.data?.totalRecordsCount || 0;
    } else {
      return 0;
    }
  };

  const handleSelectAll = () => {
    const allIds = searchData?.map((data) => data.contactNo);
    dispatch(setSelectedContacts(allIds));
  };

  const deselectAll = () => {
    dispatch(setSelectedContacts([]));
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
    dispatch(setSelectedContacts([]));

    // if (e.target.value?.length >= 2) {
    //   const filteredArray = getDataToRender().filter((item) => {
    //     if (
    //       item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
    //       item.lastName.toLowerCase().includes(e.target.value.toLowerCase())
    //     )
    //       return item;
    //   });
    //   setSearchData(filteredArray);
    // } else {
    //   setSearchData(getDataToRender());
    // }
  };

  const handleCategoryOptionPillClick = (newCategoryOption) => {
    if (newCategoryOption.id === categoryState.id) {
      setCategoryState("");
      setRestId("");
      return;
    }
    setCategoryState(newCategoryOption);
    setRestId(newCategoryOption.id);
    setCurrentPage(1);
    dispatch(setSelectedContacts([]));
  };

  // if (loading) {
  //   return (
  //     <div className={Style.loaderWrapper}>
  //       <CircularProgress color="primary" size={56} />
  //     </div>
  //   );
  // }

  return (
    <>
      <>
        <Reservations
          categories={restaurantsList?.data?.data?.original}
          categoryState={categoryState}
          handleCategoryOptionPillClick={handleCategoryOptionPillClick}
        />
        <div className={Style.emptyLine}></div>
      </>
      {loading ? (
        <div className={Style.loaderWrapper}>
          <CircularProgress color="primary" size={56} />
        </div>
      ) : (
        <>
          <div className={Style.tabsWrapper}>
            <Grid container>
              <Tabs
                tabs={tabsData}
                activeTab={activeSubTab}
                setActiveTab={(param) => {
                  setActiveSubTab(param);
                  setCurrentPage(1);
                  setSearchKey("");
                }}
              />
            </Grid>
          </div>
          <div>
            <TextField
              id="searchName"
              name="searchName"
              fullWidth
              autoComplete="off"
              type="text"
              placeholder="Search"
              value={searchKey}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: {
                  textAlign: "end",
                },
              }}
              sx={{
                marginBottom: "30px",
              }}
            />
          </div>
          {role === USER_TYPE.MANAGER && searchData?.length > 0 && (
            <FormLabel
              onClick={
                searchData?.length === selectedContacts?.length
                  ? deselectAll
                  : handleSelectAll
              }
              sx={{ textDecoration: "", "&:hover": { cursor: "pointer" } }}
            >
              {searchData?.length === selectedContacts?.length
                ? "Deselect All"
                : "Select All"}
            </FormLabel>
          )}
          <Grid container spacing={2}>
            {searchData?.map((customer, index) => (
              <Grid
                key={`${customer.id}${index}`}
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
              >
                <CustomerList
                  data={customer}
                  dashboardActions
                  showCheckBox={true}
                  reservation
                />
              </Grid>
            ))}
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 14,
                }}
              >
                {totalPageCount && searchData.length > 0 ? (
                  <Pagination
                    count={totalPageCount}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                  />
                ) : null}
              </Box>
            </Grid>
            {!searchData?.length && !loading && !searchLoading && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="noResultWrapper">
                  <img src={NoDataFound} alt="no-data-found" />
                  <Typography variant="h5">No data found!</Typography>
                </div>
              </Grid>
            )}
          </Grid>
          {searchLoading && (
            <div className={Style.loaderWrapper} style={{ height: "20vh" }}>
              <CircularProgress color="primary" size={56} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReservationTab;
