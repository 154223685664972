import React from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedContacts } from "../../redux/slices/masterDataSlice";

function Tabs({ tabs, activeTab, setActiveTab }) {
  const dispatch = useDispatch();
  const handleTabClick = (taskId) => {
    setActiveTab(taskId);
    dispatch(setSelectedContacts([]));
  };

  return (
    <>
      {tabs?.map((button, index) => (
        <Grid item xs={tabs?.length === 2 ? 6 : 4} key={button.id}>
          <Button
            variant="contained"
            color={activeTab === button.id ? "primary" : "secondary"}
            sx={{
              borderRadius:
                index === 0
                  ? "12.5px 0 0 12.5px"
                  : index === tabs.length - 1
                  ? "0 12.5px 12.5px 0"
                  : "0",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "44px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            fullWidth
            onClick={() => handleTabClick(button.id)}
          >
            {button.count}
            {button?.icons ? (
              activeTab === button.id ? (
                <img src={button.iconActive} />
              ) : (
                <img src={button.iconInactive} />
              )
            ) : null}
            <span>{button.name}</span>
          </Button>
        </Grid>
      ))}
    </>
  );
}

export default Tabs;
