import React, { useEffect, useRef, useState } from "react";
import * as htmlToImage from "html-to-image";
import Styles from "./styles.module.css";
import { IMAGE_BASE_URL } from "../../config/config";
import datalektLogo from "../../assets/images/datalektLogo.svg";
import hotelLogo from "../../assets/images/hotel-logo.png";
import QrCodeImg from "../../assets/images/QR_Code.png";
import poweredImg from "../../assets/images/powered-logo.png";
import { convertToAMPM, formatDatetime, getShortMonthDate } from "../../utils";
import { useSelector } from "react-redux";
import { QrCode } from "@mui/icons-material";

const Ticket = ({
  edit,
  restaurant,
  date,
  time,
  customerName,
  peopleCount,
  handlBookingTicket,
  remark,
  themeDetails,
}) => {
  const domEl = useRef(null);
  const domE2 = useRef(null);
  const selectedRef = useRef(null);
  const [dt, setDt] = useState(date);
  const { data } = useSelector((state) => state.user);
  useEffect(() => {
    setDt(date);
  }, [date]);

  useEffect(() => {
    if (domEl?.current) {
      downloadImage();
    }
    if (domE2?.current) {
      downloadImage();
    }
  }, [
    domEl?.current,
    domE2?.current,
    date,
    time,
    peopleCount,
    restaurant,
    remark,
  ]);

  const downloadImage = async () => {
    console.log("domEl",domEl);
    console.log("domE2",domE2);
    
    if (domEl?.current) {
      const dataUrl = await htmlToImage?.toPng(domEl?.current);
      handlBookingTicket(dataUrl);
    }
    if (domE2?.current) {
      const dataUrl = await htmlToImage?.toPng(domE2?.current);
      handlBookingTicket(dataUrl);
    }

    // download image
    // const link = document.createElement("a");
    // link.download = "html-to-img.png";
    // link.href = dataUrl;
    // link.click();
  };

  const getDay = (date) => {
    return new Date(date).getDate();
  };

  const getMonth = (date) => {
    return new Date(date)
      .toLocaleString("default", { month: "long" })
      .toUpperCase();
  };
  return (
    <>
      {/* old ticket design */}
      {/* {!(themeDetails && themeDetails != null) && ( */}
        <div className={Styles.ticket} ref={domEl} style={{ visibility: !(themeDetails && themeDetails != null) ? 'visible' : 'hidden' }}>
          <div
            style={{
              paddingTop: "90px",
            }}
          >
            <div className={Styles.titleWrapper}>
              <h3 className={Styles.title}>RESERVATION CONFIRMATION</h3>
            </div>
            <div className={Styles.ticketDetailWrap}>
              <div className={Styles.ticketDetail}>
                <div className={Styles.ticketData}>
                  <p className={Styles.hotelName}>
                    {data?.hotelName?.toUpperCase() || ""}
                  </p>
                  <h1>{restaurant?.name?.toUpperCase() || ""}</h1>
                  <h5>MR/MRS. {customerName?.toUpperCase()}</h5>
                  <h5>PAX: {peopleCount}</h5>
                  {remark && <span className={Styles.remark}>{remark}</span>}
                </div>
                <div className={Styles.dateWrap}>
                  <h2>{getDay(dt)}</h2>
                  <h6>
                    {getMonth(dt)}{" "}
                    {time ? `${time?.hh}:${time?.mm} ${time?.meridiem}` : "N.A"}
                  </h6>
                </div>
              </div>
              <div className={Styles.ticketrightBlock}>
                <h3>RESERVATION CONFIRMATION</h3>
                <h2>
                  {dt ? getShortMonthDate(dt) : "N.A"} |{" "}
                  {time ? `${time?.hh}:${time?.mm} ${time?.meridiem}` : "N.A"}
                </h2>
                {/* <h2>02 OCT 2023 | 8:00 PM</h2> */}
                <p>POWERED BY,</p>
                <img src={datalektLogo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      {/* } */}

      {/* New ticket design */}
      {/* {themeDetails && themeDetails != null && ( */}
        <div className="cardWrap" ref={domE2} style={{ visibility: (themeDetails && themeDetails != null) ? 'visible' : 'hidden' }}>
          <div
            className="topWrap"
            style={{ background: themeDetails?.primaryColor }}
          >
            <h2 style={{ color: themeDetails?.fontColor }}>
              RESERVATION CONFIRMATION
            </h2>
            <div className="hotelLogoBox">
              <img
                src={`${IMAGE_BASE_URL}/${themeDetails?.hotelLogo}`}
                alt="logo" crossOrigin="anonymous"
              />
            </div>
          </div>
          <div className="divider"></div>
          <div
            className="middleWrap"
            style={{ background: themeDetails?.primaryColor }}
          >
            <ul className="nameList">
              <li style={{ background: themeDetails?.secondaryColor }}>
                <p style={{ color: themeDetails?.fontColor }}>
                  Restaurant/Hotel Name
                </p>
                <h3 style={{ color: themeDetails?.fontColor }}>
                  {data?.hotelName?.toUpperCase() || ""}
                </h3>
                <p className="mb-0" style={{ color: themeDetails?.fontColor }}>
                  {restaurant?.name?.toUpperCase() || ""}
                </p>
              </li>
              <li style={{ background: themeDetails?.secondaryColor }}>
                <p style={{ color: themeDetails?.fontColor }}>Guest Name</p>
                <h3 style={{ color: themeDetails?.fontColor }}>
                  {customerName?.toUpperCase()}
                </h3>
                <p className="mb-0" style={{ color: themeDetails?.fontColor }}>
                  PAX: {peopleCount}
                </p>
              </li>
              <li>
                <div
                  className="dateTimeBox"
                  style={{ background: themeDetails?.secondaryColor }}
                >
                  <p style={{ color: themeDetails?.fontColor }}>Date</p>
                  <h3 style={{ color: themeDetails?.fontColor }}>
                    {dt ? getShortMonthDate(dt) : "N.A"}{" "}
                  </h3>
                </div>
                <div
                  className="dateTimeBox"
                  style={{ background: themeDetails?.secondaryColor }}
                >
                  <p style={{ color: themeDetails?.fontColor }}>Time</p>
                  <h3 style={{ color: themeDetails?.fontColor }}>
                    {" "}
                    {time ? `${time?.hh}:${time?.mm} ${time?.meridiem}` : "N.A"}
                  </h3>
                </div>
              </li>
            </ul>
          </div>
          <div className="divider"></div>
          <div
            className="bottomWrap"
            style={{ background: themeDetails?.primaryColor }}
          >
            <div
              className="contentBox"
              style={{ background: themeDetails?.secondaryColor }}
            >
              {themeDetails?.qrCode && themeDetails?.qrCode != "" && (
                <div className="qrCodeBox">
                  <p style={{ color: themeDetails?.fontColor }}>
                    Payment QR Code
                  </p>
                  <img
                    src={`${IMAGE_BASE_URL}/${themeDetails?.qrCode}`}
                    alt="" crossOrigin="anonymous"
                  />
                </div>
              )}

              <div
                className={`${
                  !(themeDetails?.qrCode && themeDetails?.qrCode != "")
                    ? "ml-0"
                    : ""
                } remarksBox`}
              >
                {remark && (
                  <p style={{ color: themeDetails?.fontColor }}>Remarks</p>
                )}
                {remark && (
                  <div className="noteBox">
                    <div className="placeholder">{remark}</div>
                  </div>
                )}
                <div
                  className={`${
                    themeDetails?.qrCode && themeDetails?.qrCode != ""
                      ? "bottom-0"
                      : ""
                  } poweredBox`}
                >
                  <img src={poweredImg} alt="" crossOrigin="anonymous" />
                </div>
              </div>
            </div>
          </div>
        </div>
      
      {/* )} */}
    </>
  );
};

export default Ticket;
