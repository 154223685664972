import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import NotFound from "./pages/not-found/NotFound";
import { RootLayout } from "./layouts/RootLayout";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import CustomerSearch from "./pages/customer/CustomerSearch";
import CustomerSearchResult from "./pages/customer/CustomerSearchResult";
import AddCustomer from "./pages/customer/AddCustomer";
import AddLater from "./pages/customer/AddLater";
import StaffDashboard from "./pages/dashboard/StaffDashboard";
import WhosHere from "./pages/whos-here/WhosHere";
import Template from "./pages/template/Template";
import CreateTemplate from "./pages/template/CreateTemplate";
import LiveTable from "./pages/liveTable/LiveTable";
import Filtered from "./pages/filtered/Filtered";
import SpecialOccasions from "./pages/special-occasions/SpecialOccasions";
import Reservation from "./pages/customer/Reservation";
import AllClientsList from "./pages/allClients/AllClientsList";
import AllClients from "./pages/allClients/AllClients";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import AboutUs from "./pages/about-us/AboutUs";
import ChooseServer from "./pages/choose-server/ChooseServer";
import QRCode from "./pages/qr-code/QRCode";
import Export from "./pages/export/Export";
import { USER_TYPE } from "./config/constants";
import RestrictedRoute from "./layouts/RestrictedRoute";
import TemplateSetting from "./pages/settings/TemplateSetting";
import History from "./pages/history/History";
import PWAInstallerPrompt from "react-pwa-installer-prompt";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import WhoseHereAlert from "./pages/whose-here-alert/WhoseHereAlert";
import { useSelector } from "react-redux";
import { useAuth } from "./hooks/useAuth";
import { isCurrentDatetimeGreaterThanGivenDatetime } from "./utils";
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const { logout } = useAuth();
  const [open, setOpen] = useState(true);
  const { lastLogin } = useSelector((state) => state.user);
  useEffect(() => {
    if (isCurrentDatetimeGreaterThanGivenDatetime(lastLogin)) {
      logout();
    }
  }, []);

  return (
    <>
      <PWAInstallerPrompt
        render={({ onClick }) => (
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Install Datalekt for Quick Access
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Click on Install button to get Datalekt App into your device.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={onClick}>
                Install
              </Button>
            </DialogActions>
          </Dialog>
        )}
        callback={(data) => {}}
      />
      <Routes>
        <Route element={<RootLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/saml2/test/login" element={<Login />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route index path="/dashboard" element={<StaffDashboard />} />
          <Route
            path="/customer-search"
            element={
              <RestrictedRoute roles={[USER_TYPE.STAFF]}>
                <CustomerSearch />
              </RestrictedRoute>
            }
          />
          <Route
            path="/customer-search-result"
            element={
              <RestrictedRoute roles={[USER_TYPE.STAFF]}>
                <CustomerSearchResult />
              </RestrictedRoute>
            }
          />
          <Route
            key="Add Record"
            path="/customer/add"
            element={
              <RestrictedRoute roles={[USER_TYPE.STAFF]}>
                <AddCustomer />
              </RestrictedRoute>
            }
          />
          <Route
            path="/customer/edit/:id"
            element={<AddCustomer edit={true} />}
          />
          <Route
            path="/customer/add-later"
            element={
              <RestrictedRoute roles={[USER_TYPE.STAFF]}>
                <AddLater />
              </RestrictedRoute>
            }
          />
          <Route path="/whos-here" element={<WhosHere />} />
          <Route
            path="/templates"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <Template />
              </RestrictedRoute>
            }
          />
          <Route
            path="/send-template"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <Template sendTemplate />
              </RestrictedRoute>
            }
          />
          <Route
            path="/create-template"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <CreateTemplate />
              </RestrictedRoute>
            }
          />
          <Route
            path="/quick-message"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <CreateTemplate quickMessage />
              </RestrictedRoute>
            }
          />
          <Route
            path="/edit-template/:templateId"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <CreateTemplate edit={true} />
              </RestrictedRoute>
            }
          />
          <Route
            path="/filtered"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <Filtered />
              </RestrictedRoute>
            }
          />
          <Route
            path="/filtered-reservations"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <Filtered reservation />
              </RestrictedRoute>
            }
          />
          <Route
            path="/filtered-checkin"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <Filtered checkin />
              </RestrictedRoute>
            }
          />
          <Route
            path="/live-table"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER, USER_TYPE.STAFF]}>
                <LiveTable />
              </RestrictedRoute>
            }
          />
          <Route
            path="/special-occasions"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <SpecialOccasions />
              </RestrictedRoute>
            }
          />
          <Route path="/reservation/:clientId" element={<Reservation />} />
          <Route
            path="/reservation/:clientId/:reservationId"
            element={<Reservation edit />}
          />
          <Route path="/reservation" element={<Reservation />} />
          <Route
            path="/all-clients"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <AllClients />
              </RestrictedRoute>
            }
          />
          <Route
            path="/all-clients-list"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <AllClientsList />
              </RestrictedRoute>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/choose-server"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <ChooseServer />
              </RestrictedRoute>
            }
          />
          <Route
            path="/qr-code"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <QRCode />
              </RestrictedRoute>
            }
          />
          <Route
            path="/export"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <Export />
              </RestrictedRoute>
            }
          />
          <Route
            path="/settings/:id"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <TemplateSetting />
              </RestrictedRoute>
            }
          />
          <Route
            path="/history/:id"
            element={
              <RestrictedRoute roles={[USER_TYPE.MANAGER]}>
                <History />
              </RestrictedRoute>
            }
          />
          {/* <Route
            path="/whos-here-alert"
            element={
              <RestrictedRoute roles={[USER_TYPE.STAFF, USER_TYPE.MANAGER]}>
                <WhoseHereAlert />
              </RestrictedRoute>
            }
          /> */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
