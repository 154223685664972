import React from "react";
import Styles from "./styles/MessageCardStyle.module.css";
import { Avatar } from "@mui/material";
import { formatDatetime } from "../../utils";
import { IMAGE_BASE_URL } from "../../config/config";

const MessageCard = ({ data }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.avatarWrapper}>
        <Avatar
          src={`${IMAGE_BASE_URL}${data?.image}`}
          variant="square"
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "12.5px",
          }}
        />
      </div>
      <div className={Styles.infoWrapper}>
        <p className={Styles.userName}>{data?.title}</p>
        <p className={Styles.message}>{data?.message}</p>
        {data?.created_at && (
          <p className={Styles.dateTime}>{formatDatetime(data?.created_at)}</p>
        )}
      </div>
    </div>
  );
};

export default MessageCard;
